export default AnimatedCard;

import React from "react";
import { animated, useInView, useSpring } from "@react-spring/web";
import { buildInteractionObserverThreshold } from "../utils/Threshold";

function AnimatedCard({ children }: { children: React.ReactNode }) {
  const [ref, isInView] = useInView({
    rootMargin: "-15% 0px -15% 0px",
    amount: buildInteractionObserverThreshold(),
  });

  const styles = useSpring({
    opacity: isInView ? 1 : 0,
    loop: false,
    config: {
      tension: 100,
    },
  });

  return (
    <animated.div ref={ref} style={styles}>
      {children}
    </animated.div>
  );
}
